import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
import bus from "@/plugins/bus";
export default {
  name: "areaAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        id: null,
        code: null,
        name: null,
        parentId: null
      },
      formRules: {
        code: [{
          required: true,
          message: '请输入区域编码',
          trigger: 'blur'
        }, {
          max: 16,
          message: '区域编码长度不能超过16',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z_0-9]+$/,
          message: '区域编码必须为字母、数字或_',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入区域名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '区域名称长度不能超过32',
          trigger: 'blur'
        }]
      },
      areaList: [],
      areaProps: {
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'name'
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.uac.area.update(this.formModel).then(callBack);
          } else {
            this.$api.uac.area.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.uac.area.detail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 101702) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
    this.$api.uac.area.list().then(res => {
      if (res.code === 200) {
        this.areaList = res.data;
      }
    });
  }
};